/* -- w3s W3 CSS framework -- */
@import "base";
@import url(https://www.w3schools.com/w3css/4/w3.css);
@import url(https://fonts.googleapis.com/css?family=Vollkorn);
@import url(https://fonts.googleapis.com/css2?family=Vollkorn&family=Vollkorn+SC);
@import url(https://fonts.googleapis.com/css?family=Lato);

/* -- style -- */
html, body {
    font-family: "Lato";
}
h1 {
    font-family: "Vollkorn SC", serif;
}
h2, h3, h4, h5, h6 {
    font-family: "Vollkorn", serif;
}
.main-wrapper {
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 0;
}
.hdr-left {
    position: absolute;
    left: 10%;
    top: 30px;
    width: 300px;
    z-index: 0;
}
.hdr-right {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 90%;
    z-index: -1;
}
.content-wrapper {
    position: relative;
    top: 400px;
    z-index: 0;
}
.home-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 0;
}